export const patientInfo = [
  {
    component: 'h3',
    children: 'Patient Information',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'First Name:',
        name: 'patientfirstname',
        key: 'patientfirstname',
      },
      {
        type: 'text',
        label: 'Middle Name:',
        name: 'patientmiddlename',
        key: 'patientmiddlename',
      },
      {
        type: 'text',
        label: 'Last Name:',
        name: 'patientlastname',
        key: 'patientlastname',
      },
      {
        type: 'text',
        label: 'Date of Birth:',
        name: 'patientdateofbirth',
        key: 'patientdateofbirth',
      },
      {
        type: 'text',
        label: 'Identification Type:',
        name: 'idType',
        key: 'idType',
      },
      {
        type: 'text',
        label: 'Identification Number:',
        name: 'idnumber',
        key: 'idnumber',
      },
    ],
  },
]
export const testInfo = [
  {
    component: 'h3',
    children: 'Test Details',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'date',
        label: 'Test Date:',
        name: 'testDate',
        key: 'testDate',
      },
      {
        type: 'time',
        label: 'Test Time:',
        name: 'testTime',
        key: 'testTime',
      },
    ],
  },
]
export const resultInfo = [
  {
    component: 'h3',
    children: 'Results',
    class: 'tw-text-2xl tw-mb-4',
  },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [

      {
        type: 'date',
        label: 'Result Date:',
        name: 'resultDate',
        key: 'resultDate',
      },
      {
        type: 'time',
        label: 'Result Time:',
        name: 'resultTime',
        key: 'resultTime',
      },
      {
        type: 'select',
        label: 'Test Result:',
        options: ['Negative', 'Positive'],
        name: 'testResult',
        key: 'testResult',
      },
    ],
  },
]
export const pharmacyInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacy Name',
        name: 'pharmacyName',
        key: 'pharmacyName',
      },
      {
        type: 'text',
        label: 'Pharmacy Address:',
        name: 'pharmacyAdd',
        key: 'pharmacyAdd',
      },
      {
        type: 'text',
        label: 'Phone Number:',
        name: 'pharmacyTelephoneNumber',
        key: 'pharmacyTelephoneNumber',
      },
      {
        type: 'text',
        label: 'Postal Code:',
        name: 'postalCode',
        key: 'postalCode',
      },
    ],
  },
]
export const rphInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacist Name:',
        name: 'rphName',
        key: 'rphName',
      },
      {
        type: 'text',
        label: 'OCP #',
        name: 'ocp',
        key: 'ocp',
      },
    ],
  },
]
